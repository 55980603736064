import React from "react";
import { useParams } from "react-router";
import { env } from "../../env";

function SetupComponent(props) {
  const { id } = useParams();
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <iframe
        src={`/SetupIntent.html?id=${id}&stripeKey=${env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`}
        title="Stripe Payment"
        width="100%"
        height="100%"
        style={{ border: "none" }}
      />
    </div>
  );
}

export default SetupComponent;
