import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  List,
  ListSubheader,
  Popover,
  Typography,
  Modal,
} from "@mui/material";
import { useState, useEffect } from "react";
import "./Distributors.css";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useAuth } from "../../hooks/useAuth";
import Tooltip from "../../common/workarounds/tooltip/PatchTooltip";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { usePageMode } from "../../hooks/usePageMode";
import { getServiceInstance } from "../../services/UserServices/UserService";
import { useLoading } from "../../hooks/useLoader";
import { useMasterData } from "../../hooks/useMasterData";
import UploadUserModal from "../../common/Modal/UploadUserModal";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import ExportToExcelHandler from "../../utilities/ExportToExcelHandler";
import {
  SelectionFilter,
  GetAllSelectedIds,
  ClearSelectionData,
  GetSelectedColumns,
} from "../../utilities/SelectionFilter";
import AdminDataTable from "../Tables/AdminDataTable/AdminDataTable";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import TableColumnModal from "../../common/TableColumnModal/TableColumnModal";
import {
  PermissionService,
  PermissionEnum,
  getModuleId,
} from "../../constants/permissionService";
import CheckboxList from "../../common/CheckboxList/CheckboxList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import filterRoles from "../../utilities/filterRoles";
import { SystemUsersEnum } from "../../constants/SystemUsers";
import { ALL_ROLE_LIST, SUBSCRIBER } from "../../constants/api-constants";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import { EmailSchema } from "../../common/ValidationSchema/ValidationSchema";
import InlineMessage from "../../common/InlineMessage/InlineMessage";
import { useFormik } from "formik";
import { subscriberDoesNotExists } from "../../screens/Distributors/constants";
import ApiServices from "../../services/ApiServices";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Confirmation from "../../common/Confirmation/Confirmation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "#fff",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};

const Distributors = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [distributorData, setDistributorData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [createUserView, setCreateUserView] = useState(false);
  const [tableView, setTableView] = useState(true);
  const [curentUserId, setCurentUserId] = useState(null);
  const [expanded, setExpanded] = useState("false");
  const [roleOptions, setRoleOptions] = useState([]);
  const [masterData, setMasterData] = useState({});
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedCarrier, setselectedCarrier] = useState([]);
  const [selectedCarrierRepresented, setselectedCarrierRepresented] = useState(
    []
  );
  const [selectedAnnualRevenue, setselectedAnnualRevenue] = useState([]);
  const [selectedareas, setselectedareas] = useState([]);
  const [selectedTechnology, setselectedtechnology] = useState([]);
  const [selectedDistributorType, setselectedDistributorType] = useState([]);
  const [selectedEnterpriseType, setselectedEnterpriseType] = useState([]);
  const [selectedSubscriberType, setselectedSubscriberType] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedAccountStatus, setSelectedAccountStatus] = useState([]);
  const [industryGroups, setIndustryGroups] = useState([]);
  const [selectedIndustryValue, setSelectedIndustryValue] = useState([]);
  const history = useHistory();
  const page = usePageMode();
  const auth = useAuth();
  const { showLoader } = useLoading();
  const [tableColumnModalVisibility, setTableColumnModalVisibility] =
    useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const userService = { ...getServiceInstance(props.routeData?.userTypeId) };
  const [openSubs, setOpenSubs] = useState(false);
  const handleCloseSubs = () => {
    formik.setFieldValue("primaryEmail", "");
    setOpenSubs(false);
  };
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const formik = useFormik({
    initialValues: {
      primaryEmail: "",
    },
    enableReinitialize: true,
    validationSchema: EmailSchema,
  });

  const [querySubEmail, setQuerySubEmail] = useState({
    searchText: "",
    PageNumber: 1,
    PageSize: 1,
    SortType: "desc",
    SortColumn: "userId",
    IsSearchInAllQuery: true,
    filter: {},
  });

  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    SortColumn: isAdminOrSuper(auth) ? "status" : "associatedStatus",
    PageNumber: 1,
    PageSize: 10,
    filter: {
      userTypes: [props.routeData?.userTypeId],
      userStatus: [0, 1],
      roles: [],
      AccountStatus: ["false", "true"],
    },
  });
  const masterDataContext = useMasterData();
  const filterAccordianHandleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const permissions = PermissionService(
    getModuleId(props.routeData.userTypeId)
  );

  useEffect(() => {
    getCurrentUserId();
    ClearSelectionData();
  }, []);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  const handleAssociation = async (selectedSubEmail = "") => {
    try {
      showLoader(true);
      const response = await ApiServices.httpGet(
        `${SUBSCRIBER}/find?emailId=${
          selectedSubEmail ? selectedSubEmail : querySubEmail.searchText
        }`
      );

      if (response.responseStatus?.isSuccess) {
        let updatedSub = response.result;
        let subExistsError = checkAddSub(auth, updatedSub, selectedSubEmail);

        await performNextStep(
          subExistsError,
          updatedSub,
          handleCloseSubs,
          selectedSubEmail,
          distributorsDetails
        );
      } else {
        whenSubscriberNotExists(response, setOpenConfirmation, showLoader);
      }
    } catch (e) {
      toast.error("Internal server error");
    } finally {
      showLoader(false);
    }
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let cols = null;
    if (props.routeData.userTypeId === 3) {
      cols = selectedCols && selectedCols.distributor;
      cols = filterForNonAdmin(auth, cols);
    } else if (props.routeData.userTypeId === 4) {
      cols = selectedCols && selectedCols.enterprise;
      cols = filterForNonAdmin(auth, cols);
    } else if (props.routeData.userTypeId === 7) {
      cols = selectedCols && selectedCols.subscriber;
      if (isAdminOrSuper(auth)) {
        cols = cols.filter((x) => x.fieldName !== "associatedStatus");
      } else {
        cols = filterForNonAdmin(auth, cols);
      }
    }
    if (cols) {
      setSelectedColumns(cols);
    }
  };

  const loadFilterOptions = async () => {
    try {
      const response = masterDataContext.masterDataValue;
      const adminData = await ApiServices.httpGet(`${ALL_ROLE_LIST}`);
      setMasterData(response);
      const filteredRoles = filterRoles(
        props.routeData.userTypeId,
        adminData?.result?.records
      );
      setRoleOptions(
        filteredRoles.map((r) => {
          return {
            value: r.roleId,
            roleId: r.roleId,
            label: r.name,
          };
        })
      );
      setIndustryGroups(
        response.result?.industryGroups?.map((r) => {
          return {
            value: r.industryGroupId,
            label: r.name,
          };
        })
      );
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const getCurrentUserId = async () => {
    try {
      setCurentUserId(auth.getCurrentUser().userId);
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFilterRoleChangeHandler = (values) => {
    setSelectedRoles(values);
  };

  const onCarrierChangeHandler = (values) => {
    setselectedCarrier(values);
  };
  const onCarrierRepresentedChangeHandler = (values) => {
    setselectedCarrierRepresented(values);
  };
  const onAnnualRevenueChangeHandler = (values) => {
    setselectedAnnualRevenue(values);
  };
  const onRoleChangeHandler = (values) => {
    setselectedareas(values);
  };
  const onTechnologyChangeHandler = (values) => {
    setselectedtechnology(values);
  };
  const onIndustryChangeHandler = (values) => {
    setSelectedIndustryValue(values);
  };
  const onDistributorTypeChangeHandler = (values) => {
    setselectedDistributorType(values);
  };
  const onEnterpriseTypeChangeHandler = (values) => {
    setselectedEnterpriseType(values);
  };
  const onSubscriberTypeChangeHandler = (values) => {
    setselectedSubscriberType(values);
  };

  const onFilterStatusChangeHandler = (values) => {
    setSelectedStatus(values);
  };

  const onFilterAccountStatusChangeHandler = (values) => {
    setSelectedAccountStatus(values);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // Distributors List API
  const distributorsDetails = async () => {
    if (permissions && !permissions.includes(PermissionEnum.LIST)) {
      history.push("/dashboard");
      return;
    }
    try {
      showLoader(true);
      const disbtributorData = await userService.getUsers(
        `${props.routeData?.getUsers}?query=${JSON.stringify(query)}`
      );
      showLoader(false);
      // starts here: added code for pre-selection if data/record selected/checked
      let checkedIds = GetAllSelectedIds("userId");
      if (checkedIds && disbtributorData?.result?.records) {
        disbtributorData.result.records = disbtributorData?.result?.records.map(
          (ele) => {
            if (checkedIds.includes(ele.userId)) {
              ele.isChecked = true;
            } else {
              ele.isChecked = false;
            }
            return ele;
          }
        );
      }
      // ends here
      setDistributorData(disbtributorData?.result?.records);
      setTotalPages(disbtributorData?.result?.totalPages);
      setPageSize(disbtributorData?.result?.pageSize);
      setSelectionColumns();
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const onSuccessToggle = (val) => {
    if (val) {
      distributorsDetails();
    }
  };

  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };

  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  const clearFilterHandler = () => {
    setSelectedRoles([]);
    setSelectedStatus([]);
    setSelectedAccountStatus([]);
    setselectedCarrier([]);
    setselectedCarrierRepresented([]);
    setselectedAnnualRevenue([]);
    setselectedtechnology([]);
    setselectedareas([]);
    setSelectedIndustryValue([]);
    setselectedDistributorType([]);
    setselectedEnterpriseType([]);
    setselectedSubscriberType([]);
  };

  const applyFilterHandler = () => {
    ClearSelectionData();
    setQuery((prevState) => {
      return {
        ...prevState,
        PageNumber: 1,
        filter: {
          ...prevState.filter,
          userStatus: selectedStatus,
          userTypes: [],
          AccountStatus: selectedAccountStatus,
          roles: selectedRoles.map((r) => {
            return { roleId: r };
          }),
          carriers: selectedCarrier.map((r) => {
            return { carrierId: r };
          }),
          CarrierRepresents: selectedCarrierRepresented.map((r) => {
            return { CarrierRepresentId: r };
          }),
          AnnualRevenues: selectedAnnualRevenue.map((r) => {
            return { annualRevenueId: r };
          }),
          Technologies: selectedTechnology.map((r) => {
            return { TechnologyId: r };
          }),
          Areas: selectedareas.map((r) => {
            return { AreaId: r };
          }),
          IndustryGroups: selectedIndustryValue.map((r) => {
            return { IndustryGroupId: r };
          }),
          DistributorTypes: selectedDistributorType.map((r) => {
            return { DistributorTypeId: r };
          }),
          EnterpriseTypes: selectedEnterpriseType.map((r) => {
            return { EnterpriseTypeId: r };
          }),
          SubscriberTypes: selectedSubscriberType.map((r) => {
            return { SubscriberTypeId: r };
          }),
        },
      };
    });
  };

  const searchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return { ...prevState, PageNumber: 1, searchText: event.target.value };
      });
    }, 1000);
  };
  // Pagination

  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };

  useEffect(() => {
    distributorsDetails();
  }, [query]);

  const handleExport = async () => {
    try {
      let ids = GetAllSelectedIds("userId");
      let queryState = { ...query };
      queryState.SelectedIds = ids;
      queryState.filter.selectedColumns = GetSelectedColumns(
        props?.routeData?.userTypeId
      );
      showLoader(true);
      const exportedContent = await userService.exportData(
        `?query=${JSON.stringify(queryState)}`
      );
      if (exportedContent?.result?.file) {
        ExportToExcelHandler(
          exportedContent.result.file.fileContents,
          exportedContent.result.file.fileDownloadName
        );
      } else {
        toast.error(exportedContent?.responseStatus?.error);
      }
      showLoader(false);
    } catch (e) {
      showLoader(false);
      toast.error("Internal server error");
    }
  };

  const onCancelTableColumnSelection = () => {
    setTableColumnModalVisibility(false);
    setSelectionColumns();
  };

  const onSaveTableColumnSelection = (componentName, columns) => {
    let oldData = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    if (oldData) {
      let data = { ...oldData, [componentName]: columns };
      localStorage.setItem("selectedColumns", JSON.stringify(data));
    }
    //TODO: to be deleted
    // else {
    //   let newData = { [componentName]: columns };
    //   localStorage.setItem("selectedColumns", JSON.stringify(newData));
    // }
    setSelectionColumns();
    setTableColumnModalVisibility(false);
  };

  return (
    <Grid spacing={2} container className="distributors-container">
      <Grid container item xs={12} className="my-class">
        <Grid item xs={10}>
          <Box pt={2} pr={4} pl={4} pb={0}>
            <h5>{props.routeData?.newTitle}</h5>
            {/* <div>
              <ToastContainer closeButton={true} position="top-right" />
            </div> */}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className="top-right-actions">
            <div className="add-welcome-widgets">
              <div>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                >
                  <div className="expanded-filter">
                    <div className="filter-options">
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                          <ListSubheader
                            component="div"
                            id="nested-list-subheader"
                          >
                            Filters will be applied to your grid
                          </ListSubheader>
                        }
                      >
                        <Accordion
                          expanded={expanded === "accountStatus"}
                          onChange={filterAccordianHandleChange(
                            "accountStatus"
                          )}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>
                              Account Status
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CheckboxList
                              id="accountStatus"
                              options={[
                                { value: "true", label: "Locked" },
                                { value: "false", label: "Unlocked" },
                              ]}
                              onChange={onFilterAccountStatusChangeHandler}
                              selectedValues={selectedAccountStatus}
                            />
                          </AccordionDetails>
                        </Accordion>
                        {props?.routeData?.userTypeId === 7 && (
                          <Accordion
                            expanded={expanded === "AnnualRevenues"}
                            onChange={filterAccordianHandleChange(
                              "AnnualRevenues"
                            )}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="accordion-summary"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Annual Revenue
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="AnnualRevenues"
                                options={[
                                  { value: 1, label: "0-100k" },
                                  { value: 2, label: "101 to 500k" },
                                  { value: 3, label: "500k to 1,250,000" },
                                  {
                                    value: 4,
                                    label: "1,250,000 to 2,500,000",
                                  },
                                  { value: 5, label: "2,500,000 to 5,000,000" },
                                  {
                                    value: 6,
                                    label: "5,000,000 to 10,000,000",
                                  },
                                  {
                                    value: 7,
                                    label: "10,000,000 to 25,000,000",
                                  },
                                  {
                                    value: 8,
                                    label: "25,000,000 to 100,000,000",
                                  },
                                  {
                                    value: 9,
                                    label: "100,000,000 to 500,000,000",
                                  },
                                  {
                                    value: 10,
                                    label: "500,000,000 to 1,000,000,000",
                                  },
                                  {
                                    value: 11,
                                    label: "1,000,000,000 and over",
                                  },
                                ]}
                                onChange={onAnnualRevenueChangeHandler}
                                selectedValues={selectedAnnualRevenue}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                        {props?.routeData?.userTypeId === 3 && (
                          <Accordion
                            expanded={expanded === "carriers"}
                            onChange={filterAccordianHandleChange("carriers")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="accordion-summary"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Carrier
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="carriers"
                                options={[
                                  { value: 1, label: "Mutual" },
                                  { value: 2, label: "Public" },
                                  { value: 3, label: "Private" },
                                ]}
                                onChange={onCarrierChangeHandler}
                                selectedValues={selectedCarrier}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                        {props?.routeData?.userTypeId === 7 && (
                          <Accordion
                            expanded={expanded === "CarrierRepresents"}
                            onChange={filterAccordianHandleChange(
                              "CarrierRepresents"
                            )}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="accordion-summary"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Carrier Represented
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="CarrierRepresents"
                                options={[
                                  { value: 1, label: "Hanover" },
                                  { value: 2, label: "Kemper" },
                                  { value: 3, label: "Safeco" },
                                  { value: 4, label: "Travelers" },
                                  { value: 5, label: "Hartford" },
                                ]}
                                onChange={onCarrierRepresentedChangeHandler}
                                selectedValues={selectedCarrierRepresented}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                        {props?.routeData?.userTypeId === 3 && (
                          <Accordion
                            expanded={expanded === "DistributorTypes"}
                            onChange={filterAccordianHandleChange(
                              "DistributorTypes"
                            )}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="accordion-summary"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Distributor Type
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="DistributorTypes"
                                options={[
                                  { value: 1, label: "Agents/Broker" },
                                  { value: 2, label: "MGA/Wholeseller" },
                                  { value: 3, label: "Insurance/Carrier" },
                                  {
                                    value: 4,
                                    label: "Claims/Restoration Company",
                                  },
                                  { value: 5, label: "Risk Manager" },
                                  { value: 6, label: "Consulting Company" },
                                  { value: 7, label: "Technology Company" },
                                  { value: 8, label: "Publisher" },
                                  { value: 9, label: "Training Company" },
                                  {
                                    value: 10,
                                    label: "Industry Group/ Trade Association",
                                  },
                                ]}
                                onChange={onDistributorTypeChangeHandler}
                                selectedValues={selectedDistributorType}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}

                        {props?.routeData?.userTypeId === 4 && (
                          <Accordion
                            expanded={expanded === "EnterpriseTypes"}
                            onChange={filterAccordianHandleChange(
                              "EnterpriseTypes"
                            )}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="accordion-summary"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Enterprise Type
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="EnterpriseTypes"
                                options={[
                                  { value: 1, label: "Agents/Broker" },
                                  { value: 2, label: "MGA/Wholeseller" },
                                  { value: 3, label: "Insurance/Carrier" },
                                  {
                                    value: 4,
                                    label: "Claims/Restoration Company",
                                  },
                                  { value: 5, label: "Risk Manager" },
                                  { value: 6, label: "Consulting Company" },
                                  { value: 7, label: "Technology Company" },
                                  { value: 8, label: "Publisher" },
                                  { value: 9, label: "Training Company" },
                                  {
                                    value: 10,
                                    label: "Industry Group/ Trade Association",
                                  },
                                ]}
                                onChange={onEnterpriseTypeChangeHandler}
                                selectedValues={selectedEnterpriseType}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                        <Accordion
                          expanded={expanded === "IndustryGroups"}
                          onChange={filterAccordianHandleChange(
                            "IndustryGroups"
                          )}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="accordion-summary"
                          >
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>
                              Industry Groups/ Membership
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CheckboxList
                              id="IndustryGroups"
                              options={industryGroups}
                              onChange={onIndustryChangeHandler}
                              selectedValues={selectedIndustryValue}
                            />
                          </AccordionDetails>
                        </Accordion>
                        {(props?.routeData?.userTypeId === 3 ||
                          props?.routeData?.userTypeId === 4) && (
                          <Accordion
                            expanded={expanded === "Areas"}
                            onChange={filterAccordianHandleChange("Areas")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="accordion-summary"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Major Areas
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="Areas"
                                options={[
                                  { value: 1, label: "Personal" },
                                  { value: 2, label: "Commercial" },
                                  { value: 3, label: "Employee Benefits" },
                                  { value: 4, label: "Professional Lines" },
                                ]}
                                onChange={onRoleChangeHandler}
                                selectedValues={selectedareas}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                        {(props?.routeData?.userTypeId === 3 ||
                          props?.routeData?.userTypeId === 4) && (
                          <Accordion
                            expanded={expanded === "role"}
                            onChange={filterAccordianHandleChange("role")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="accordion-summary"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Role
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="role"
                                options={roleOptions}
                                onChange={onFilterRoleChangeHandler}
                                selectedValues={selectedRoles}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                        {isAdminOrSuper(auth) && (
                          <Accordion
                            expanded={expanded === "status"}
                            onChange={filterAccordianHandleChange("status")}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Status
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="status"
                                options={[
                                  { value: 1, label: "Active" },
                                  { value: 0, label: "Inactive" },
                                ]}
                                onChange={onFilterStatusChangeHandler}
                                selectedValues={selectedStatus}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                        {(props?.routeData?.userTypeId === 3 ||
                          props?.routeData?.userTypeId === 4) && (
                          <Accordion
                            expanded={expanded === "Technologies"}
                            onChange={filterAccordianHandleChange(
                              "Technologies"
                            )}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="accordion-summary"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Technology
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="Technologies"
                                options={[
                                  { value: 1, label: "Tech 1" },
                                  { value: 2, label: "Tech 2" },
                                ]}
                                onChange={onTechnologyChangeHandler}
                                selectedValues={selectedTechnology}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                        {props?.routeData?.userTypeId === 7 && (
                          <Accordion
                            expanded={expanded === "SubscriberTypes"}
                            onChange={filterAccordianHandleChange(
                              "SubscriberTypes"
                            )}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="accordion-summary"
                            >
                              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                Subscriber Type
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <CheckboxList
                                id="SubscriberTypes"
                                options={[
                                  { value: 1, label: "Agents/Broker" },
                                  { value: 2, label: "MGA/Wholeseller" },
                                  { value: 3, label: "Insurance/Carrier" },
                                  {
                                    value: 4,
                                    label: "Claims/Restoration Company",
                                  },
                                  { value: 5, label: "Risk Manager" },
                                  { value: 6, label: "Consulting Company" },
                                  { value: 7, label: "Technology Company" },
                                  { value: 8, label: "Publisher" },
                                  { value: 9, label: "Training Company" },
                                  {
                                    value: 10,
                                    label: "Industry Group/ Trade Association",
                                  },
                                ]}
                                onChange={onSubscriberTypeChangeHandler}
                                selectedValues={selectedSubscriberType}
                              />
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </List>
                    </div>
                    <div className="modal-footer">
                      <Button
                        variant="outlined"
                        className="action-button mr-4"
                        onClick={clearFilterHandler}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        className="action-button"
                        onClick={() => {
                          applyFilterHandler();
                          handleClose();
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </Popover>
              </div>

              {permissions && permissions.includes(PermissionEnum.CREATE) && (
                <Tooltip
                  placement="bottom"
                  title={props.routeData.addUserTooltip}
                >
                  <AddOutlinedIcon
                    name="add-icon"
                    onClick={() => {
                      if (
                        props.routeData?.userTypeId ===
                          SystemUsersEnum.SUBSCRIBER &&
                        !isAdminOrSuper(auth)
                      ) {
                        setOpenSubs(true);
                      } else {
                        page.setEditMode(true);
                        history.push(props.routeData?.addRoute);
                      }
                    }}
                  />
                </Tooltip>
              )}

              {permissions && permissions.includes(PermissionEnum.LIST) && (
                <Tooltip placement="bottom" title="Filter">
                  <FilterAltOutlinedIcon
                    onClick={handleClick}
                    title="filter-icon"
                  />
                </Tooltip>
              )}

              {permissions && permissions.includes(PermissionEnum.LIST) && (
                <Tooltip placement="bottom" title="Change View">
                  <BorderColorIcon
                    data-testid="TableColumnSelectionIcon"
                    onClick={() => {
                      setTableColumnModalVisibility(true);
                    }}
                  />
                </Tooltip>
              )}

              {permissions && permissions.includes(PermissionEnum.LIST) && (
                <Tooltip placement="bottom" title="Export to Excel">
                  <IosShareRoundedIcon
                    data-testid="IosShareRoundedIcon"
                    onClick={handleExport}
                  />
                </Tooltip>
              )}

              {window.location.pathname === "/subscribers" &&
                permissions &&
                permissions.includes(PermissionEnum.CREATE) && (
                  <div>
                    <UploadUserModal
                      distributorsDetails={distributorsDetails}
                      bulkUploadTemplate={
                        isAdminOrSuper(auth)
                          ? props.routeData.bulkUploadadminTemplate
                          : props.routeData.bulkUploadTemplate
                      }
                      userService={userService}
                      routeData={props.routeData}
                      title={"Bulk Upload Subscribers"}
                    />
                  </div>
                )}
            </div>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box pt={2} pr={4} pl={4} pb={0} mb={4}>
          {permissions && permissions.includes(PermissionEnum.LIST) && (
            <AdminDataTable
              routeData={props.routeData}
              tableView={tableView}
              setTableView={setTableView}
              createUserView={createUserView}
              setCreateUserView={setCreateUserView}
              onCheckboxChange={(e, value) => {
                const { checked } = e.target;
                if (value === "all") {
                  setDistributorData((items) => {
                    SelectionFilter(items, "multiple", checked);
                    return items.map((data) => {
                      return {
                        ...data,
                        isChecked: checked,
                      };
                    });
                  });
                } else {
                  setDistributorData((items) => {
                    return items.map((user) => {
                      let userRecord = { ...user };
                      if (user.userId === value) {
                        SelectionFilter(user, "single", checked);
                        userRecord.isChecked = checked;
                      }
                      return userRecord;
                    });
                  });
                }
              }}
              // userRoleId={userRoleId}
              currentUserId={curentUserId}
              query={query}
              handleChangePage={handleChangePageNumber}
              success={onSuccessToggle}
              adminTable={distributorData}
              // onEditSuccess={onEditSuccess}
              totalPages={totalPages}
              pageSize={pageSize}
              onSearch={searchHandler}
              onSort={sortHandler}
              onPageSizeChange={pageSizeChangeHandler}
              // userRole={userRole}
              columns={selectedColumns}
              permissions={permissions}
              PermissionEnum={PermissionEnum}
              masterData={masterData}
              handleAssociation={handleAssociation}
              isAdminOrSuperAdmin={isAdminOrSuper(auth)}
            />
          )}
          {openConfirmation && (
            <Confirmation
              open={openConfirmation}
              title={`Confirmation`}
              description={subscriberDoesNotExists}
              onCancel={() => {
                setOpenConfirmation(false);
              }}
              onConfirm={() => {
                setOpenConfirmation(false);
                localStorage.setItem(
                  "DefaultSubscriberEmail",
                  formik.values.primaryEmail
                );
                handleCloseSubs();
                page.setEditMode(true);
                page.setBreadcrumbRootNode({
                  title: props.routeData?.title,
                  path: props.routeData?.listRoute,
                });
                history.push(props.routeData?.addRoute);
              }}
              cancelButtonLabel="Cancel"
              confirmButtonLabel="Create"
            />
          )}
          {openSubs && (
            <Modal
              open={openSubs}
              onClose={handleCloseSubs}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="file-upload-modal">
                <div className="modal-header">
                  <div className="modal-header-left">
                    {"Associate Subscriber"}
                  </div>
                  <div className="modal-header-right">
                    <CloseOutlinedIcon
                      onClick={handleCloseSubs}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div className="modal-body">
                  <div>
                    <label htmlFor="primaryEmail">Primary Email Address</label>
                    <MandatoryField />
                    <input
                      id="primaryEmail"
                      name="primaryEmail"
                      className="form-label form-control"
                      type="email"
                      placeholder="Enter primary email address"
                      onChange={(e) => {
                        setQuerySubEmail((prevState) => {
                          return { ...prevState, searchText: e.target.value };
                        });
                        formik.handleChange(e);
                      }}
                      value={formik.values.primaryEmail}
                      autoComplete="off"
                    />
                    <InlineMessage error={formik.errors.primaryEmail} />
                  </div>
                </div>
                <div className={`modal-footer "modal-header-right"`}>
                  <div modal-footer>
                    <Button
                      variant="outlined"
                      className="action-button"
                      onClick={() => {
                        handleCloseSubs();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className="action-button"
                      onClick={async () => handleAssociation()}
                      disabled={!formik.isValid}
                    >
                      Associate Subscriber
                    </Button>
                  </div>
                </div>
              </Box>
            </Modal>
          )}
          {tableColumnModalVisibility && (
            <TableColumnModal
              componentName={props.routeData.componentName}
              isOpen={tableColumnModalVisibility}
              onCancelTableColumnSelection={onCancelTableColumnSelection}
              onSaveTableColumnSelection={onSaveTableColumnSelection}
              selection={selectedColumns}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

function filterForNonAdmin(auth, cols) {
  if (!isAdminOrSuper(auth)) {
    cols = cols.filter((x) => x.fieldName !== "status");
  }
  return cols;
}

function isAdminOrSuper(auth) {
  return [SystemUsersEnum.ADMIN, SystemUsersEnum.SUPERADMIN].includes(
    auth.getRole()
  );
}

/*istanbul ignore next */
function whenSubscriberNotExists(response, setOpenConfirmation, showLoader) {
  if (
    !response.responseStatus?.isSuccess &&
    response.responseStatus.code === 400
  ) {
    setOpenConfirmation(true);
    showLoader(false);
  } else {
    toast.error(response.responseStatus?.error);
    showLoader(false);
  }
}

/*istanbul ignore next */
async function performNextStep(
  subExistsError,
  updatedSub,
  handleCloseSubs,
  selectedSubEmail,
  distributorsDetails
) {
  if (!selectedSubEmail && subExistsError) {
    toast.error("Subscriber already associated.");
  } else {
    await makeUpdateRequest(
      updatedSub,
      handleCloseSubs,
      selectedSubEmail,
      subExistsError,
      distributorsDetails
    );
  }
}

/*istanbul ignore next */
function checkAddSub(auth, updatedSub, selectedSubEmail = "") {
  let subExistsError = false;
  switch (auth.getRole()) {
    case SystemUsersEnum.ENTERPRISE:
      if (updatedSub.enterpriseIds?.includes(auth.getCurrentUser()?.userId)) {
        if (selectedSubEmail) {
          updatedSub.enterpriseIds = updatedSub.enterpriseIds.filter(
            (x) => x !== auth.getCurrentUser().userId
          );
        }
        subExistsError = true;
      } else {
        updatedSub.enterpriseIds = [
          ...updatedSub.enterpriseIds,
          auth.getCurrentUser()?.userId,
        ];
      }
      break;
    case SystemUsersEnum.DISTRIBUTOR:
      if (updatedSub.distributorIds?.includes(auth.getCurrentUser()?.userId)) {
        if (selectedSubEmail) {
          updatedSub.distributorIds = updatedSub.distributorIds.filter(
            (x) => x !== auth.getCurrentUser().userId
          );
        }
        subExistsError = true;
      } else {
        updatedSub.distributorIds = [
          ...updatedSub.distributorIds,
          auth.getCurrentUser()?.userId,
        ];
      }
      break;
    default:
      break;
  }
  return subExistsError;
}
/*istanbul ignore next */
async function makeUpdateRequest(
  updatedSub,
  handleCloseSubs,
  selectedSubEmail,
  subExistsError,
  distributorsDetails
) {
  const response3 = await ApiServices.httpPut(SUBSCRIBER, updatedSub);
  if (response3.responseStatus?.isSuccess) {
    distributorsDetails();
    if (selectedSubEmail && subExistsError) {
      toast.success("Subscriber Unassociated successfully");
    } else {
      toast.success("Subscriber associated successfully");
      handleCloseSubs();
    }
  } else {
    toast.error(response3.responseStatus?.error);
  }
}

export default Distributors;
