import { Modal, Button, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./AddContentDialog.css";
import React, { useState, useEffect } from "react";
import DataTable from "../../../Tables/DataTable/DataTable";
import { useLoading } from "../../../../hooks/useLoader";
import { useMasterData } from "../../../../hooks/useMasterData";
import ApiServices from "../../../../services/ApiServices";
import {
  BUNDLE,
  PARENT_DISTRIBUTOR,
} from "../../../../constants/api-constants";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import Filter from "../../../../common/Filter/Filter";
import { FILTER_OPTION } from "../../../../constants/filterOptions";
import {
  SelectionFilter,
  GetAllSelectedIds,
  ClearSelectionData,
} from "../../../../utilities/SelectionFilter";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { ContentPublishStatus } from "../../constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  width: "1100px !important",
  bgcolor: "#fff",
  border: "1px solid #000",
  boxShadow: 24,
  p: 0,
};

const AddContentDialog = (props) => {
  const { open, closeDialogHandler, userId, contentId, listContentData, id } =
    props;
  const { showLoader } = useLoading();
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [catalogFilterOptions, setCatalogFilterOptions] = React.useState(
    FILTER_OPTION.ADD_CATALOG_LIST
  );
  const [adminTable, setAdminTableData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [isNew, setIsNew] = useState(false);
  const [showFilter, setshowFilter] = useState(false);
  const [query, setQuery] = useState({
    searchText: "",
    SortType: "desc",
    IgnorePaging: true,
    SortColumn: "userId",
    PageNumber: 1,
    PageSize: 10,
    filter: {
      status: [1],
      publishStatus:
        props.publishStatus === ContentPublishStatus.PUBLISHED ? [1] : [],
    },
  });

  const [selectedItem, setSelectedItem] = useState({});
  const masterDataContext = useMasterData();

  useEffect(() => {
    ClearSelectionData();
  }, []);

  useEffect(() => {
    getContentList();
    setSelectionColumns();
  }, [query]);

  const loadDropdownOption = async () => {
    try {
      showLoader(true);
      const response = masterDataContext.masterDataValue;
      const distributorresponse = await ApiServices.httpGet(PARENT_DISTRIBUTOR);
      showLoader(false);
      const catalogOptions = [...catalogFilterOptions];

      // LOB
      catalogOptions[3].options = response.result.lineOfBusiness.map((r) => {
        return {
          value: r.lineOfBusinessId,
          roleId: r.lineOfBusinessId,
          label: r.name,
        };
      });
      // Type of Form
      catalogOptions[5].options = response.result.typeOfForm.map((r) => {
        return {
          value: r.typeOfFormId,
          roleId: r.typeOfFormId,
          label: r.name,
        };
      });
      // Countries
      catalogOptions[1].options = response.result.countries.map((r) => {
        return {
          value: r.countryId,
          roleId: r.countryId,
          label: r.name,
        };
      });
      catalogOptions[2].options = distributorresponse.result.map((r) => {
        return {
          value: r.userId,
          label: r.name,
        };
      });
      setCatalogFilterOptions(catalogOptions);
    } catch (e) {
      showLoader(false);
    }
  };

  useEffect(() => {
    if (!loaded) {
      loadDropdownOption();
      setLoaded(true);
    }
  }, []);
  /*istanbul ignore next */
  const applyModalFilterHandler = (filter) => {
    ClearSelectionData();
    setSelectedItem(filter);
    setQuery((prevState) => {
      return {
        ...prevState,
        filter: {
          ...prevState.filter,
          contentType: filter.contentType || [],
          countries:
            filter.countries?.map((item) => {
              return { countryId: item };
            }) || [],
          userIds:
            filter.distributor?.map((item) => {
              return item;
            }) || [],
          lineOfBusiness:
            filter.lineOfBusiness?.map((item) => {
              return { lineOfBusinessId: item };
            }) || [],
          publishStatus:
            filter.publishStatus?.map((item) => {
              return item;
            }) || [],
          TypeOfContent:
            filter.TypeOfContent?.map((item) => {
              return item;
            }) || [],
          typeOfForm:
            filter.typeOfForm?.map((item) => {
              return { typeOfFormId: item };
            }) || [],
          result:
            filter.result?.map((item) => {
              return { userId: item };
            }) || [],
          DocumentTypes:
            filter.DocumentTypes?.map((item) => {
              return item;
            }) || [],
          contentFillable: filter.contentFillable,
        },
      };
    });
  };

  const setSelectionColumns = () => {
    let selectedCols = localStorage.getItem("selectedColumns")
      ? JSON.parse(localStorage.getItem("selectedColumns"))
      : null;
    let contentDialogCols = selectedCols?.contentDialog || [];
    if (contentDialogCols) {
      setSelectedColumns(contentDialogCols);
    }
  };

  const getContentList = async () => {
    try {
      showLoader(true);
      let firstParam = userId;
      let secondParam = 0;
      query.IgnorePaging = true;
      const adminData = await ApiServices.httpGet(
        `${BUNDLE}/content/${firstParam}/${secondParam}/${encodeURIComponent(
          JSON.stringify(query)
        )}`
      );
      showLoader(false);
      let checkedIds =
        listContentData && listContentData.map((el) => el.contentId);
      if (checkedIds && adminData?.result?.records) {
        adminData.result.records = adminData?.result?.records.filter(
          (ele) =>
            !checkedIds.includes(ele.contentId) &&
            (ele.documentType < 2 || ele.contents?.length > 0)
        );
      }
      setAdminTableData(adminData?.result?.records);
      // ends here
      setTotalPages(adminData?.result?.totalPages);
      setPageSize(adminData?.result?.pageSize);
    } catch (e) {
      toast.error("Internal server error");
    }
  };
  /*istanbul ignore next */
  const onCheckboxChangeHandler = (e, value) => {
    const { checked } = e.target;
    if (value === "all") {
      setAdminTableData((items) => {
        SelectionFilter(items, "multiple", checked, "contentId");
        return items.map((data) => {
          return {
            ...data,
            isChecked: checked,
          };
        });
      });
    } else {
      setAdminTableData((items) => {
        return items.map((user) => {
          let userRecord = { ...user };
          if (user.contentId === value) {
            SelectionFilter(user, "single", checked, "contentId");
            userRecord.isChecked = checked;
          }
          return userRecord;
        });
      });
    }
    setIsNew(true);
  };
  /*istanbul ignore next */
  const associateContent = async () => {
    try {
      let checkedIds = GetAllSelectedIds("contentId");
      let payload = {
        contentId: contentId,
        userId: userId,
        associateContentIds: [...checkedIds],
      };

      let response = await ApiServices.httpPost(`${BUNDLE}/content`, payload);
      if (response?.responseStatus?.isSuccess) {
        ClearSelectionData();
        toast.success(response.responseStatus.description);
        if (props.publishStatus === ContentPublishStatus.PUBLISHED) {
          toast.success(
            "Bundle need not to be republished. These Content(s) and/or Catalog(s) will automatically appear to the subscriber(s) who has access to this published Bundle."
          );
        }
        closeDialogHandler(false);
      } else {
        toast.error(response?.responseStatus?.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    }
  };
  /*istanbul ignore next */
  const onSearchHandler = (event) => {
    ClearSelectionData();
    setTimeout(() => {
      setQuery((prevState) => {
        return {
          ...prevState,
          PageNumber: 1,
          searchText: event.target.value,
        };
      });
    }, 1000);
  };
  const handleFilter = (event) => {
    setshowFilter(true);
    setAnchorEl(event.currentTarget);
  };
  /*istanbul ignore next */
  const sortHandler = (sortOrder, sortBy) => {
    setQuery((prevState) => {
      if (prevState.SortColumn === sortBy) {
        return {
          ...prevState,
          SortType: sortOrder === "asc" ? "desc" : "asc",
        };
      } else {
        return {
          ...prevState,
          SortColumn: sortBy,
          SortType: "asc",
        };
      }
    });
  };
  /*istanbul ignore next */
  const handleChangePageNumber = (event, newPage) => {
    setQuery((prevState) => {
      return { ...prevState, PageNumber: newPage };
    });
  };
  /*istanbul ignore next */
  const onClose = () => {
    setshowFilter(false);
  };
  const handleClose = () => {
    onClose();
  };
  /*istanbul ignore next */
  const pageSizeChangeHandler = (PageSize) => {
    setQuery((prevState) => {
      return { ...prevState, PageSize: PageSize, PageNumber: 1 };
    });
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        closeDialogHandler(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="custom-modal-content">
        <div className="modal-header">
          <div className="modal-header-left">List of Items</div>
          <div
            className="modal-header-right"
            onClick={() => {
              closeDialogHandler(false);
            }}
            style={{ cursor: "pointer" }}
          >
            <CloseOutlinedIcon data-testid="CloseNewAdminPopup" />
          </div>
        </div>
        <div className="modal-body-cus">
          <div className="action-items">
            <div className="add-welcome-widgetsfilter-cus-filter-icon">
              <Tooltip placement="bottom" title="Filter">
                <FilterAltOutlinedIcon
                  onClick={handleFilter}
                  data-testid="FilterAltOutlinedIcon-uniqueId"
                />
              </Tooltip>
              <div>
                {showFilter && (
                  <Filter
                    anchorEl={anchorEl}
                    id={id}
                    open={open}
                    options={catalogFilterOptions}
                    onApplyFilter={applyModalFilterHandler}
                    onClose={handleClose}
                    selected={selectedItem}
                  />
                )}
              </div>
            </div>
            <Button
              disabled={!isNew}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                associateContent();
              }}
              data-testid="dialog-add-button"
              className={isNew ? "add-button-align" : "add-disabled-button"}
            >
              Add
            </Button>
            <Button
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={() => {
                closeDialogHandler(false);
              }}
              data-testid="dialog-cancel-button"
              className="cancel-button-align"
            >
              Cancel
            </Button>
          </div>
          <DataTable
            uniqueKey="contentId"
            onCheckboxChange={onCheckboxChangeHandler}
            columns={selectedColumns}
            query={query}
            adminTable={adminTable}
            onSearch={onSearchHandler}
            onSort={sortHandler}
            onPageSizeChange={pageSizeChangeHandler}
            handleChangePage={handleChangePageNumber}
            totalPages={totalPages}
            pageSize={pageSize}
          />
        </div>
        <div className="modal-footer"></div>;
      </Box>
    </Modal>
  );
};

export default AddContentDialog;
