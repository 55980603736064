export const SelectionFilter = (data, key, isChecked, id = "userId") => {
  let oldData = GetSelectionData();
  if (!oldData) {
    SetInitialSelectionData();
  }

  if (data) {
    if (oldData && oldData.length > 0) {
      getUpdateOldData(key, isChecked, oldData, id, data);
    } else {
      SetSelectionData(
        keyCheckedFlag(key, "single", true) ? [data] : [...data]
      );
    }
  }
};

const keyCheckedFlag = (key, value, flag) => {
  return key === value && flag;
};

const SetInitialSelectionData = () => {
  localStorage.setItem("selectionFilter", []);
};

export const ClearSelectionData = () => {
  localStorage.removeItem("selectionFilter");
};

export const GetSelectionData = () => {
  let data = localStorage.getItem("selectionFilter")
    ? JSON.parse(localStorage.getItem("selectionFilter"))
    : null;
  return data;
};

const SetSelectionData = (data) => {
  localStorage.setItem("selectionFilter", JSON.stringify(data));
};

export const GetAllSelectedIds = (field) => {
  try {
    const data = localStorage.getItem("selectionFilter")
      ? JSON.parse(localStorage.getItem("selectionFilter"))
      : [];
    return data ? data.map((element) => element[field]) : [];
  } catch (e) {
    console.log(e);
  }
};

export const GetSelectedColumns = (userTypeId) => {
  let cols = localStorage.getItem("selectedColumns")
    ? JSON.parse(localStorage.getItem("selectedColumns"))
    : null;

  if (userTypeId === 1 || userTypeId === 2) {
    return getSelectedCols(cols, "admin");
  } else if (userTypeId === 3) {
    return getSelectedCols(cols, "distributor");
  } else if (userTypeId === 4) {
    return getSelectedCols(cols, "enterprise");
  } else if (userTypeId === 5) {
    return getSelectedCols(cols, "distInternalUser");
  } else if (userTypeId === 6) {
    return getSelectedCols(cols, "entInternalUser");
  } else if (userTypeId === 7) {
    return getSelectedCols(cols, "subscriber");
  } else if (userTypeId === 8) {
    return getSelectedCols(cols, "contents");
  } else if (userTypeId === 9) {
    return getSelectedCols(cols, "role");
  } else if (userTypeId === 10) {
    return getSelectedCols(cols, "catalog");
  } else if (userTypeId === 11) {
    return getSelectedCols(cols, "bundle");
  } else if (userTypeId === 12) {
    return getSelectedCols(cols, "listOfVendorDataColumns");
  } else if (userTypeId === 13) {
    return getSelectedCols(cols, "listOfContactDataColumns");
  } else {
    return [];
  }
};

export const getSelectedCols = (cols, type) => {
  let selectedCols = [];
  try {
    cols[type]?.forEach((el) => {
      if (el.isSelected && el.showInSelection && el.exportLabel)
        selectedCols.push(el.exportLabel);
    });
  } catch (e) {
    console.log(e);
  }

  return selectedCols;
};

function getUpdateOldData(key, isChecked, oldData, id, data) {
  if (keyCheckedFlag(key, "multiple", isChecked)) {
    const oldDataSetMultiple = new Set(oldData.map((d) => d[id]));
    const newData = data.filter((d) => !oldDataSetMultiple.has(d[id]));
    oldData = [...oldData, ...newData];
  } else if (keyCheckedFlag(key, "multiple", !isChecked)) {
    const removedDataMultiple = new Set(data.map((d) => d[id]));
    oldData = oldData.filter((el) => !removedDataMultiple.has(el[id]));
  } else if (keyCheckedFlag(key, "single", isChecked)) {
    const oldDataSetSingle = new Set(oldData.map((d) => d[id]));
    if (!oldDataSetSingle.has(data[id])) {
      oldData.push(data);
    }
  } else if (keyCheckedFlag(key, "single", !isChecked)) {
    oldData = oldData.filter((el) => el[id] !== data[id]);
  }
  SetSelectionData(oldData);
}
