import React, { useState } from "react";
import "./SignInWidget.css";
import { Button, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Cookies from "js-cookie";
import {
  AdminColumns,
  SubscriberColumns,
  DistributorColumns,
  EnterpriseColumns,
  DistributorInternalUserColumns,
  EnterpriseInternalUserColumns,
  ManageDocumentsListColumns,
  ManageDocumentsSuccessOrErrorListColumns,
  SubscriberAccessDataColumns,
  listOfVendorDataColumn,
  VendorContactsColumns,
  // SolutionDetailsColumns,
} from "../../common/TableColumnModal/DefaultDataColumns";
import {
  ContentDataColumns,
  ListOfContentDataColumns,
  ContentModalDataColumns,
  ListOfContentCatalogDataColumns,
} from "../../common/TableColumnModal/ContentDataColumns";
import {
  DefaultApprovalAssociatedColumns,
  DefaultApprovalColumns,
  DefaultRoleColumns,
  ManageSubscriberCatalogBundleColumns,
  ManageSubscriberColumns,
} from "../../common/TableColumnModal/DefaultRoleColumns";
import { DefaultCatalogColumns } from "../../common/TableColumnModal/CatalogDataColumns";
import {
  CustomListBundleColumns,
  DefaultBundleColumns,
} from "../../common/TableColumnModal/BundleDataColumns";
import CustomLoginWidget from "./CustomLoginWidget";
import ApiServices from "../../services/ApiServices";
import {
  LOGIN_URL,
  MODULES_PERMISSION_API,
  VERIFY2FA,
} from "../../constants/api-constants";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import AuthWidget2FA from "./AuthWidget2FA";
import { MandatoryField } from "../../common/MandatoryFieldsIcon/MandatoryField";
import { useFormik } from "formik";
import { SignupSchema } from "../../common/ValidationSchema/ValidationSchema";
import InlineMessage from "../../common/InlineMessage/InlineMessage";
import { useAuth } from "../../hooks/useAuth";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { fetchImageAsBase64 } from "../../utilities/common";

const Login = () => {
  const auth = useAuth();
  const authState = localStorage.getItem("loginData") ? true : false;
  const history = useHistory();
  const [postSubmitLoading, setPostSubmitLoading] = useState(false);
  const currentUserData =
    localStorage.getItem("loginData") &&
    JSON.parse(localStorage.getItem("loginData"));
  const [roleOptions, setRoleOptions] = useState(
    currentUserData?.user?.user?.roles || []
  );
  const [loginData, setLoginData] = useState({});
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [mode, setMode] = useState(authState ? 3 : 1);
  const formik = useFormik({
    initialValues: {
      role: "0",
      internalRole: "0",
    },
    enableReinitialize: true,
    validationSchema: SignupSchema,
  });

  useState(() => {
    let hasSessionExpired = localStorage.getItem("SessionExpired");
    if (hasSessionExpired) {
      toast.error("Session expired");
      localStorage.removeItem("SessionExpired");
    }
    let hasSessionLogout = localStorage.getItem("SessionLogout");
    if (hasSessionLogout) {
      toast.success(hasSessionLogout);
      localStorage.removeItem("SessionLogout");
    }
  });

  const onSuccess = (tokens) => {
    let defaultCols = {
      admin: AdminColumns,
      distributor: DistributorColumns,
      enterprise: EnterpriseColumns,
      subscriber: SubscriberColumns,
      subscriberaccesscol: SubscriberAccessDataColumns,
      contents: ContentDataColumns,
      role: DefaultRoleColumns,
      manageSubscriber: ManageSubscriberColumns,
      manageSubscriberCatalogBundle: ManageSubscriberCatalogBundleColumns,
      distInternalUser: DistributorInternalUserColumns,
      entInternalUser: EnterpriseInternalUserColumns,
      approval: DefaultApprovalColumns,
      approvalAssociated: DefaultApprovalAssociatedColumns,
      catalog: DefaultCatalogColumns,
      bundle: DefaultBundleColumns,
      listOfContent: ListOfContentDataColumns,
      contentDialog: ContentModalDataColumns,
      listOfContentCatalogDataColumns: ListOfContentCatalogDataColumns,
      listOfContentBundleDataColumns: ListOfContentCatalogDataColumns,
      listcustombundledatacolumns: CustomListBundleColumns,
      manageDocumentsListColumns: ManageDocumentsListColumns,
      ManageDocumentsSuccessOrErrorListColumns:
        ManageDocumentsSuccessOrErrorListColumns,
      listOfVendorDataColumns: listOfVendorDataColumn,
      listOfContactDataColumns: VendorContactsColumns,
      // listOfSolutionDataColumns: SolutionDetailsColumns,
    };
    localStorage.setItem("selectedColumns", JSON.stringify(defaultCols));
  };

  const onSignIn = async (emailValue, passwordValue, keepSignedIn) => {
    try {
      setEmail(emailValue);
      setPassword(passwordValue);

      if (keepSignedIn) {
        Cookies.set("username", emailValue, { expires: 30 }); // Cookie expires in 30 days
        Cookies.set("password", passwordValue, { expires: 30 }); // In a real application, you should not store the password in plain text.
      } else {
        // If the checkbox is not checked, remove any existing credentials from the cookie.
        Cookies.remove("username");
        Cookies.remove("password");
      }

      let payload = {
        email: emailValue,
        password: passwordValue,
      };
      const response = await ApiServices.httpPost(LOGIN_URL, payload);
      if (response?.result) {
        toast.success(response?.responseStatus?.description);
        //check if 2FA enabled then show 2FA screen else goto roles
        if (!response.result?.is2FA) {
          localStorage.setItem("loginData", JSON.stringify(response.result));
          setRoleOptions(response.result.user.user.roles);
          setLoginData(response.result.user);
          onSuccess(response.result?.token);
        }
        setMode(response.result?.is2FA ? 2 : 3);
      } else {
        setPostSubmitLoading(false);
        toast.error(response?.responseStatus?.error);
      }
    } catch (e) {
      console.log("err");
    }
  };

  const resendOTP = async () => {
    try {
      let payload = {
        email: email,
        password: password,
      };
      const response = await ApiServices.httpPost(LOGIN_URL, payload);
      if (response?.result) {
        toast.success(response?.responseStatus?.description);
      } else {
        toast.error(response?.responseStatus?.error);
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  const onSubmitOtp = async (otp) => {
    try {
      setPostSubmitLoading(true);
      let payload = {
        email: email,
        password: password,
        otp: otp,
      };
      const response = await ApiServices.httpPost(VERIFY2FA, payload);
      if (response?.result) {
        toast.success(response?.responseStatus?.description);
        setRoleOptions(response.result.user.user.roles);
        setLoginData(response.result.user);
        onSuccess(response.result?.token);
        localStorage.setItem("loginData", JSON.stringify(response.result));
        localStorage.setItem("2FAVerified", true);
        setMode(3);
      } else {
        setPostSubmitLoading(false);
        toast.error(response?.responseStatus?.error);
        localStorage.setItem("2FAVerified", false);
      }
    } catch (e) {
      console.log("err", e);
    }
  };

  const isValid = () => {
    if (formik.values.role === "0") {
      return false;
    }

    if (formik.values.role !== "0") {
      const selectedRole = roleOptions.find(
        (r) => r.roleId === Number(formik.values.role)
      );
      if (
        selectedRole?.internalUserRole?.length > 0 &&
        formik.values.internalRole === "0"
      ) {
        return false;
      }
    }

    return true;
  };

  const onNext = async () => {
    let selectedRole = roleOptions.filter(
      (el) => el.roleId === Number(formik.values.role)
    );
    const intRole =
      formik.values.role === "3" || formik.values.role === "4"
        ? formik.values.internalRole
        : 0;

    auth.setRole(selectedRole[0]);
    auth.setInternalRole(intRole);

    auth.setCurrentUser(loginData.user || currentUserData?.user?.user);
    const getPermissions = await ApiServices.httpGet(MODULES_PERMISSION_API);
    if (
      getPermissions.responseStatus.code === 200 &&
      getPermissions.responseStatus.isSuccess
    ) {
      auth.setModules(getPermissions.result.modules);
      if (getPermissions.result.user.logoGcpFileUrl) {
        await fetchImageAsBase64(
          getPermissions.result.user.logoGcpFileUrl
        ).then((data) => {
          auth.setBrandImage(data);
        });
      } else {
        auth.setBrandImage(getPermissions.result.user.logoGcpFileUrl);
      }
    }
    // } else {
    //   toast.error("you are not authorize for this action.");
    // }

    if (history?.location?.state?.from?.pathname) {
      history.push(history.location.state.from.pathname); // return url
    } else {
      history.push("/dashboard"); // Default landing page
    }
  };

  return (
    <div className="myContainer">
      {/* <div>
        {!postSubmitLoading && (
          <ToastContainer closeButton={true} position="top-right" />
        )}
      </div> */}
      <Container maxWidth="md" className="main-grid-container">
        <Grid container justifyContent="center" className="main-grid">
          <Grid item xs={5} md={5} className="grid-left">
            <div style={{ padding: 22 }}>
              <img
                src="/images/onivas-logo.png"
                alt="logo"
                className="side-logo"
              />
            </div>
            <Typography className="login-para" varient="h5" paragraph>
              Software Solutions that Simplify Insurance
            </Typography>
            <div className="cloud-container">
              <div style={{ padding: 25 }}>
                <img padding src="/images/login-ring.png" alt="logo ring" />
              </div>
              <div className="cl1"></div>
              <div className="cl2"></div>
              <div className="cl3">
                <div className="cus-icon-align">
                  <LinkedInIcon className="icon-margin" />
                  <FacebookIcon className="icon-margin" />
                  <YouTubeIcon className="icon-margin" />
                  <TwitterIcon className="icon-margin" />
                  <InstagramIcon className="icon-margin" />
                </div>
              </div>
              <div className="cl4"></div>
              <div className="cl5"></div>
            </div>
          </Grid>

          {mode === 1 && (
            <Grid item xs={7} md={7} className="login-form">
              <div className="signup-container">
                <p>Don't have an Account?</p>

                <Button
                  variant="outlined"
                  className="signup-button"
                  onClick={() => {
                    history.push("/signup");
                  }}
                >
                  SIGN UP
                </Button>
              </div>

              <div className="login-form-content">
                <p>Welcome to Inchanted!</p>
                <p>Login here to start the journey...</p>
              </div>
              <div
                id="custom-signin"
                class="custom-auth-container"
                style={{ display: "block" }}
              >
                <CustomLoginWidget
                  onSubmit={onSignIn}
                  postSubmitLoading={postSubmitLoading}
                />
              </div>
            </Grid>
          )}

          {mode === 2 && (
            <Grid item xs={7} md={7} className="login-form">
              <div className="login-form-content">
                <p>Confirm One-Time Password</p>
              </div>
              <div
                id="custom-signin"
                class="custom-auth-container"
                style={{ display: "block" }}
              >
                <AuthWidget2FA
                  resendOTP={resendOTP}
                  onSubmitOtp={onSubmitOtp}
                  postSubmitLoading={postSubmitLoading}
                />
              </div>
            </Grid>
          )}

          {mode === 3 && (
            <Grid item xs={7} md={7} className="login-form">
              <div className="login-form-content">
                <p>Please select your role...</p>
              </div>

              <div className="login-form-content">
                <label htmlFor="role">Role</label>
                <MandatoryField />
                <select
                  onChange={formik.handleChange}
                  className="form-select form-control select-user"
                  aria-label="Default select example"
                  name="role"
                  id="role"
                  value={formik.values.role}
                >
                  <option key={0} value={0} selected>
                    Select Role
                  </option>
                  {roleOptions?.map((opt) => (
                    <option key={opt.roleId} value={opt.roleId}>
                      {opt.name}
                    </option>
                  ))}
                </select>
                <InlineMessage error={formik.errors.role} />
              </div>
              <div className="login-form-content right">
                <Button
                  type="submit"
                  variant="contained"
                  className="action-button"
                  id="sign-in-id"
                  onClick={onNext}
                  disabled={!isValid()}
                >
                  Next
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default Login;
