export const typeOfContentOptions = [
  {
    value: 7,
    name: "AUDIO",
    fileType: "",
    accept: [
      "audio/m4a",
      "audio/flac",
      "audio/alac",
      "audio/mp3",
      "audio/mp4",
      "audio/wav",
      "audio/wma",
      "audio/aac",
      "audio/dsd",
      "audio/pcm",
    ],
  },
  {
    value: 5,
    name: "EXCEL",
    fileType: "xlsx",
    accept: [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/xlsx",
      "application/csv",
      "application/xlx",
    ],
  },
  {
    value: 2,
    name: "FORMS_XFDL",
    fileType: "",
    accept: ["application/xfdl"],
  },
  { value: 8, name: "GIF", fileType: "gif", accept: ["image/gif"] },
  {
    value: 3,
    name: "JPG",
    fileType: "jpg",
    accept: ["image/jpeg", "image/jpg"],
  },
  {
    value: 1,
    name: "PDF",
    fileType: "pdf",
    accept: ["application/pdf"],
  },
  { value: 9, name: "PNG", fileType: "png", accept: ["image/png"] },
  {
    value: 6,
    name: "PPT",
    fileType: "pptx",
    accept: [
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/pptx",
      "application/ppt",
    ],
  },
  {
    value: 10,
    name: "VIDEO",
    fileType: "",
    accept: [
      "video/mp4",
      "video/mov",
      "video/wmv",
      "video/avchd",
      "video/flv",
      "video/f4v",
      "video/swf",
      "video/mkv",
      "video/webm",
      "video/html5",
      "video/mpeg-2",
    ],
  },
  { value: 11, name: "WEBLINK", fileType: "", accept: ["image/tiff"] },
  {
    value: 4,
    name: "WORD",
    fileType: "docx",
    accept: [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/doc",
      "application/docx",
    ],
  },
];

export const allAcceptedExtentionsDocuments = [
  "audio/m4a",
  "audio/flac",
  "audio/alac",
  "audio/mp3",
  "audio/mp4",
  "audio/wav",
  "audio/wma",
  "audio/aac",
  "audio/dsd",
  "audio/pcm",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/xfdl",
  "image/gif",
  "image/jpeg",
  "application/jpg",
  "application/pdf",
  "image/png",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "video/mp4",
  "video/mov",
  "video/wmv",
  "video/avchd",
  "video/flv",
  "video/f4v",
  "video/swf",
  "video/mkv",
  "video/webm",
  "video/html5",
  "video/mpeg-2",
  "image/tiff",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/xlsx",
  "application/csv",
  "application/xlx",
  "application/doc",
  "application/docx",
  "application/pptx",
  "application/ppt",
];

export const brandLogoAcceptedFormats = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/gif",
];

export const TypeofConstantENUM = {
  WEBLINK: "11",
};

export const contentTypeOptions = [
  { value: 1, name: "Free" },
  { value: 2, name: "Paid" },
];

export const previewTypeOptions = [
  { value: 1, name: "For 1 Page" },
  { value: 2, name: "For All page" },
  { value: 3, name: "Marketing Description" },
];

export const confirmationNoDocAttached =
  "Nothing will Preview. Are you sure you want continue?";
export const cofirmationToPublish =
  "Are you sure you want to publish this Content?";
export const catalogCofirmationToPublish =
  "Are you sure you want to publish this Catalog?";
export const bundleCofirmationToPublish =
  "Are you sure you want to publish this Bundle?";
export const exitConfirmationMessage =
  "You have not saved your work. Are you sure you want to discard the changes?";
export const deleteSubscriptionConfirmationMessage =
  "Are you sure you want to delete the subscription details?";
export const deleteSubscriptionConfirmationMessages =
  "Are you sure you want to delete the document(s) ?";
export const publishCatalogConfirmationMessage =
  "The catalog/bundle consists of some unpublished item. Are you sure you want to proceed?";

export const subscriberDoesNotExists =
  "The email address provided does not match any existing subscriber. Please double-check the email address or you can create a new subscriber.";
export const SUBSCRIPTION_ENUM = {
  1: "Monthly",
  2: "Quarterly",
  3: "Semi-Annual",
  4: "Annual",
};

export const DocumentType = {
  CONTENT: 1,
  CATALOG: 2,
  BUNDLE: 3,
};

export const BreadCrumbRoutes = {
  "basic-information": "Basic Information",
  "internal-users": "User/Contacts",
  content: "Content List",
  ContentInformation: "Content Information",
  ContentPreview: "Content Preview",
  catalog: "Catalog List",
  CatalogInformation: "Catalog Information",
  ContentList: "Content List",
  bundle: "Bundle List",
  BundleInformation: "Bundle Information",
  CatalogList: "Catalog List",
  ManageSubscribers: "Manage Subscribers",
  AccessDetails: "Access Details",
};

export const ContentPublishStatus = {
  DRAFT: 0,
  PUBLISHED: 1,
  MODIFIED: 2,
};

export const UserAddOnType = {
  HIGHLIGHTING_DURING_SHARE: 1,
  GLOSSARY_OF_TERMS: 2,
};

export const subscriptionStatusFilters = [
  {
    value: 1,
    label: "Active",
  },
  {
    value: 4,
    label: "Upcoming",
  },
  {
    value: 0,
    label: "Expired",
  },
];
