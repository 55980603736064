import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Tooltip,
  TextField,
  MenuItem,
  Select,
  Button,
  Typography,
} from "@mui/material";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import DataTable from "../Tables/DataTable/DataTable";
import "./Reports.css";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import {
  REPORT,
  REPORT_EXPORT,
  REPORT_OPTIONS,
} from "../../constants/api-constants";
import ApiServices from "../../services/ApiServices";
import { toast } from "react-toastify";
import { useLoading } from "../../hooks/useLoader";
import ExportToExcelHandler from "../../utilities/ExportToExcelHandler";
import { COLUMN_TYPES } from "../../constants/common";

/*istanbul ignore next */
const Reports = (props) => {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [adminTable, setAdminTableData] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [exportDateFrom, setExportDateFrom] = useState(null);
  const [exportDateTo, setExportDateTo] = useState(null);
  const [selectedReport, setSelectedReport] = useState("");
  const { showLoader } = useLoading();
  const [previousPageToken, setPreviousPageToken] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [reportOptions, setReportOptions] = useState([]);
  const [dateFromErrorMessage, setDateFromErrorMessage] = useState("");
  const [dateToErrorMessage, setDateToErrorMessage] = useState("");

  useEffect(() => {
    setSelectedColumns([]);
    subsReport();
  }, []);

  const handleReportChange = (event) => {
    setSelectedReport(event.target.value);
  };
  const handleNextPage = () => {
    handleChangePageNumber(nextPageToken);
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handlePreviousPage = () => {
    handleChangePageNumber(previousPageToken);
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
  };

  const pageSizeChangeHandler = (newPageSize) => {
    const parsedPageSize = parseInt(newPageSize);
    setPageSize(parsedPageSize);
    handleChangePageNumber(null, newPageSize, true);
  };

  const handleDateFromChange = (newDateFrom) => {
    const today = moment();
    setDateFrom(newDateFrom);
    if (
      moment(newDateFrom, "MM/DD/YYYY", true).isValid() &&
      moment(newDateFrom).isAfter(today)
    ) {
      setDateFromErrorMessage("'Date From' must be today or a past date.");
    } else {
      setDateFromErrorMessage("");
    }
    if (dateTo && moment(newDateFrom).isAfter(dateTo)) {
      setDateToErrorMessage("");
      setDateTo(null);
    }
    if (dateTo && moment(newDateFrom).add(1, "months").isBefore(dateTo)) {
      setDateFromErrorMessage("");
      setDateToErrorMessage("");
      setDateTo(null);
    }
  };

  const calculateMaxDate = () => {
    if (dateFrom) {
      const oneMonthFromStartDate = moment(dateFrom).add(1, "months");
      return oneMonthFromStartDate.isBefore(moment())
        ? oneMonthFromStartDate
        : moment();
    }
    return moment();
  };

  const maxDate = calculateMaxDate();

  const handleDateToChange = (newDateTo) => {
    setDateTo(newDateTo);
    if (
      dateFrom &&
      (moment(newDateTo).subtract(1, "months").isAfter(dateFrom) ||
        moment(newDateTo).isBefore(dateFrom))
    ) {
      setDateToErrorMessage(
        "Please select a 'Date From' within one month of the 'Date To'."
      );
    } else {
      const today = moment();
      if (
        moment(newDateTo, "MM/DD/YYYY", true).isValid() &&
        moment(newDateTo).isAfter(today)
      ) {
        setDateToErrorMessage("'Date To' must be today or a past date.");
      } else {
        setDateToErrorMessage("");
      }
    }
  };
  const isDateToGreaterThanToday = dateTo && moment(dateTo).isAfter(moment());
  const isDateToBeforeDateFrom =
    dateTo && moment(dateTo).isBefore(moment(dateFrom));

  const isSearchDisabled =
    !dateFrom?._isValid ||
    !dateTo?._isValid ||
    isDateToGreaterThanToday ||
    isDateToBeforeDateFrom ||
    !dateFrom ||
    !dateTo ||
    !selectedReport;

  const mapFieldType = (field) => {
    if (field.toLowerCase().includes("date")) return COLUMN_TYPES.DATE;
    if (
      field.toLowerCase().includes("is") ||
      field.toLowerCase().includes("status")
    )
      return COLUMN_TYPES.BASIC;
    return COLUMN_TYPES.BASIC;
  };

  const flattenFields = (obj, parentKey = "") => {
    const fields = [];
    Object.keys(obj).forEach((key) => {
      const fieldKey = parentKey ? `${parentKey}.${key}` : key;
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        fields.push(...flattenFields(obj[key], fieldKey));
      } else {
        const label = key
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase());
        fields.push({
          fieldName: fieldKey,
          label: label,
          exportLabel: "",
          sort: false,
          isSelected: true,
          type: mapFieldType(fieldKey),
          width: 250,
          showInSelection: false,
        });
      }
    });

    return fields;
  };

  const flattenDataRows = (data) => {
    return data.map((record) => {
      const flattenedRecord = {};
      Object.keys(record).forEach((key) => {
        if (typeof record[key] === "object" && record[key] !== null) {
          Object.keys(record[key]).forEach((nestedKey) => {
            flattenedRecord[`${key}.${nestedKey}`] = record[key][nestedKey];
          });
        } else {
          if (key === "loginTime" || key === "createdOn") {
            flattenedRecord[key] = moment(record[key]).format(
              "MM/DD/YYYY hh:mm A"
            );
          } else {
            flattenedRecord[key] = record[key];
          }
        }
      });
      return flattenedRecord;
    });
  };

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleChangePageNumber = async (
    pageToken = "",
    updatedPageSize = pageSize,
    resetPageTokens = false
  ) => {
    try {
      const payload = {
        pageSize: parseInt(updatedPageSize),
        pageNumber: 1,
        previousPageToken: resetPageTokens ? null : previousPageToken,
        nextPageToken: resetPageTokens ? null : nextPageToken,
        ignorePaging: false,
        reportName: selectedReport,
        filter: {
          timeZone: userTimeZone,
          keyword: "",
          dateFrom: dateFrom
            ? moment(dateFrom).format("YYYY-MM-DDT00:00:00.000[Z]")
            : "",
          dateTo: dateTo
            ? moment(dateTo).format("YYYY-MM-DDT00:00:00.000[Z]")
            : "",
        },
      };
      const response = await ApiServices.httpPost(REPORT, payload);
      if (response.responseStatus.isSuccess) {
        const records = response?.result?.records;
        const flattenedRecords = flattenDataRows(records);
        setAdminTableData(flattenedRecords || []);
        setNextPageToken(response?.result?.nextPageToken);
        setPreviousPageToken(response?.result?.previousPageToken);
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const subsReport = async () => {
    try {
      const payload = {};
      const response = await ApiServices.httpPost(REPORT_OPTIONS, payload);
      if (response.responseStatus.isSuccess) {
        setReportOptions(response.result);
      }
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const reportDetails = async () => {
    try {
      const payload = {
        pageSize: 10,
        pageNumber: 1,
        ignorePaging: false,
        reportName: selectedReport,
        filter: {
          timeZone: userTimeZone,
          keyword: "",
          dateFrom: dateFrom
            ? moment(dateFrom).format("YYYY-MM-DDT00:00:00.000[Z]")
            : "",
          dateTo: dateTo
            ? moment(dateTo).format("YYYY-MM-DDT00:00:00.000[Z]")
            : "",
        },
      };
      const response = await ApiServices.httpPost(REPORT, payload);
      if (response.responseStatus.isSuccess) {
        const records = response?.result?.records;
        setExportDateFrom(dateFrom);
        setExportDateTo(dateTo);
        if (records.length > 0) {
          const columns = flattenFields(records[0]);
          setSelectedColumns(columns);
          const flattenedRecords = flattenDataRows(records);
          setAdminTableData(flattenedRecords);
          setNextPageToken(response?.result?.nextPageToken || null);
          setPreviousPageToken(response?.result?.previousPageToken || null);
          toast.success("Report Generated Successfully");
        } else {
          toast.error(
            "No records found. Please refine your search criteria and try again."
          );
          setAdminTableData([]);
        }
      } else {
        toast.error(response.responseStatus.error);
      }
    } catch (e) {
      toast.error("Internal server error");
    }
  };

  const handleExport = async () => {
    try {
      let payload = {
        pageSize: 10,
        pageNumber: 1,
        nextPageToken: nextPageToken,
        previousPageToken: previousPageToken,
        ignorePaging: false,
        reportName: selectedReport,
        filter: {
          timeZone: userTimeZone,
          keyword: "",
          dateFrom: exportDateFrom
            ? moment(exportDateFrom).format("YYYY-MM-DDT00:00:00.000[Z]")
            : "",
          dateTo: exportDateTo
            ? moment(exportDateTo).format("YYYY-MM-DDT00:00:00.000[Z]")
            : "",
        },
      };
      showLoader(true);
      let exportedContent;
      exportedContent = await ApiServices.httpPost(REPORT_EXPORT, payload);
      if (exportedContent?.result?.file) {
        ExportToExcelHandler(
          exportedContent.result.file.fileContents,
          exportedContent.result.file.fileDownloadName
        );
      }
      showLoader(false);
    } catch (e) {
      console.error("error", e);
    }
  };

  return (
    <Grid spacing={2} container className="reports-container">
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Box pt={2} pr={4} pl={4} pb={0}>
            <h5>Reports</h5>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        item
        xs={12}
        alignItems="center"
        pl={4}
        pr={4}
        ml={2}
      >
        <Grid item xs={3}>
          <Select
            labelId="report-select-label"
            fullWidth
            value={selectedReport}
            onChange={handleReportChange}
            displayEmpty
            style={{ height: "39px" }}
          >
            <MenuItem value="" disabled>
              Choose a report
            </MenuItem>
            {reportOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={
                <span>
                  Date From
                  <span style={{ color: "red", marginLeft: "3px" }}> *</span>
                </span>
              }
              value={dateFrom}
              onChange={handleDateFromChange}
              maxDate={moment()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: "500", color: "black" },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "MM/DD/YYYY",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={2}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={
                <span>
                  Date To
                  <span style={{ color: "red", marginLeft: "3px" }}> *</span>
                </span>
              }
              value={dateTo}
              onChange={handleDateToChange}
              minDate={dateFrom}
              maxDate={maxDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: "500", color: "black" },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "MM/DD/YYYY",
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={reportDetails}
            disabled={isSearchDisabled}
            style={{ height: "38px", marginTop: "-2px" }}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          {dateFromErrorMessage && (
            <Typography className="reports-error-message" color="error">
              {dateFromErrorMessage}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          {dateToErrorMessage && (
            <Typography className="reports-error-message" color="error">
              {dateToErrorMessage}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <Grid item xs={12} justifyContent={"flex-end"} display={"flex"} mr={2}>
        <div className="add-welcome-widgets">
          <Tooltip
            placement="bottom"
            title={
              adminTable.length > 0 ? "Export to Excel" : "No data to export"
            }
          >
            <IosShareRoundedIcon
              data-testid="IosShareRoundedIcon"
              onClick={adminTable.length > 0 ? handleExport : null}
              style={{
                cursor: adminTable.length > 0 ? "pointer" : "not-allowed",
                background: adminTable.length > 0 ? "#1aaefa" : "lightgray",
              }}
            />
          </Tooltip>
        </div>
      </Grid>
      <Grid item xs={12} ml={4} className="reports-message-div">
        <Typography className="reports-message-typography ">
          Please note that reports can only be generated for a maximum period of
          one month.Select a 'Date To' that is within one month of the 'Date
          From'.
        </Typography>
      </Grid>
      <Grid item xs={11.8} ml={2}>
        {adminTable.length > 0 && (
          <DataTable
            uniqueKey="userReports"
            adminTable={adminTable}
            columns={selectedColumns}
            serachBarNotRequired={true}
            checkboxesNotRequired={true}
            customPagination={true}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            hasNextPage={nextPageToken}
            hasPreviousPage={previousPageToken}
            onPageSizeChange={pageSizeChangeHandler}
            currentPage={currentPage}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Reports;
