import { useContext, createContext } from "react";

export const VendorInit = {
  isEditMode: true,
  setEditMode(val) {
    this.isEditMode = val;
  },
};

export const VendorContext = createContext(VendorInit);

export const useVendorContext = () => useContext(VendorContext);
